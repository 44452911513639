import React from "react";
import "./ComingSoon.css";
import NavBar from "../../partials/header/AuthNavBar";
import Footer from "../../partials/footer/Footer";
const ComingSoon = () => {
  return (
    
    <div className="coming-soon">
        <NavBar />
      <div className="coming-soon-content">
        <h1>Coming Soon</h1>
        <p>We are working on something awesome</p>
      </div>
      <Footer/>
    </div>
  );
};

export default ComingSoon;